exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktiviteter-alpint-js": () => import("./../../../src/pages/aktiviteter/alpint.js" /* webpackChunkName: "component---src-pages-aktiviteter-alpint-js" */),
  "component---src-pages-aktiviteter-fisking-js": () => import("./../../../src/pages/aktiviteter/fisking.js" /* webpackChunkName: "component---src-pages-aktiviteter-fisking-js" */),
  "component---src-pages-aktiviteter-golf-js": () => import("./../../../src/pages/aktiviteter/golf.js" /* webpackChunkName: "component---src-pages-aktiviteter-golf-js" */),
  "component---src-pages-aktiviteter-index-js": () => import("./../../../src/pages/aktiviteter/index.js" /* webpackChunkName: "component---src-pages-aktiviteter-index-js" */),
  "component---src-pages-aktiviteter-kultur-js": () => import("./../../../src/pages/aktiviteter/kultur.js" /* webpackChunkName: "component---src-pages-aktiviteter-kultur-js" */),
  "component---src-pages-aktiviteter-langrenn-js": () => import("./../../../src/pages/aktiviteter/langrenn.js" /* webpackChunkName: "component---src-pages-aktiviteter-langrenn-js" */),
  "component---src-pages-aktiviteter-sykling-js": () => import("./../../../src/pages/aktiviteter/sykling.js" /* webpackChunkName: "component---src-pages-aktiviteter-sykling-js" */),
  "component---src-pages-aktiviteter-vandring-js": () => import("./../../../src/pages/aktiviteter/vandring.js" /* webpackChunkName: "component---src-pages-aktiviteter-vandring-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-bryllup-index-js": () => import("./../../../src/pages/bryllup/index.js" /* webpackChunkName: "component---src-pages-bryllup-index-js" */),
  "component---src-pages-hyttegjester-index-js": () => import("./../../../src/pages/hyttegjester/index.js" /* webpackChunkName: "component---src-pages-hyttegjester-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kongsgaarden-apningstider-js": () => import("./../../../src/pages/kongsgaarden/apningstider.js" /* webpackChunkName: "component---src-pages-kongsgaarden-apningstider-js" */),
  "component---src-pages-kongsgaarden-baerekraft-js": () => import("./../../../src/pages/kongsgaarden/baerekraft.js" /* webpackChunkName: "component---src-pages-kongsgaarden-baerekraft-js" */),
  "component---src-pages-kongsgaarden-index-js": () => import("./../../../src/pages/kongsgaarden/index.js" /* webpackChunkName: "component---src-pages-kongsgaarden-index-js" */),
  "component---src-pages-kongsgaarden-interior-js": () => import("./../../../src/pages/kongsgaarden/interior.js" /* webpackChunkName: "component---src-pages-kongsgaarden-interior-js" */),
  "component---src-pages-kongsgaarden-jobb-js": () => import("./../../../src/pages/kongsgaarden/jobb.js" /* webpackChunkName: "component---src-pages-kongsgaarden-jobb-js" */),
  "component---src-pages-kongsgaarden-kontaktoss-js": () => import("./../../../src/pages/kongsgaarden/kontaktoss.js" /* webpackChunkName: "component---src-pages-kongsgaarden-kontaktoss-js" */),
  "component---src-pages-kongsgaarden-omoss-js": () => import("./../../../src/pages/kongsgaarden/omoss.js" /* webpackChunkName: "component---src-pages-kongsgaarden-omoss-js" */),
  "component---src-pages-kongsgaarden-webkamera-js": () => import("./../../../src/pages/kongsgaarden/webkamera.js" /* webpackChunkName: "component---src-pages-kongsgaarden-webkamera-js" */),
  "component---src-pages-kurskonferanser-index-js": () => import("./../../../src/pages/kurskonferanser/index.js" /* webpackChunkName: "component---src-pages-kurskonferanser-index-js" */),
  "component---src-pages-nyheter-index-js": () => import("./../../../src/pages/nyheter/index.js" /* webpackChunkName: "component---src-pages-nyheter-index-js" */),
  "component---src-pages-overnatting-index-js": () => import("./../../../src/pages/overnatting/index.js" /* webpackChunkName: "component---src-pages-overnatting-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-selskap-index-js": () => import("./../../../src/pages/selskap/index.js" /* webpackChunkName: "component---src-pages-selskap-index-js" */),
  "component---src-pages-smaksopplevelser-bord-js": () => import("./../../../src/pages/smaksopplevelser/bord.js" /* webpackChunkName: "component---src-pages-smaksopplevelser-bord-js" */),
  "component---src-pages-smaksopplevelser-index-js": () => import("./../../../src/pages/smaksopplevelser/index.js" /* webpackChunkName: "component---src-pages-smaksopplevelser-index-js" */),
  "component---src-pages-smaksopplevelser-sendt-js": () => import("./../../../src/pages/smaksopplevelser/sendt.js" /* webpackChunkName: "component---src-pages-smaksopplevelser-sendt-js" */)
}

